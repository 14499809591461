<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h4>Drivers Licence and Traffic Offence Check</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step1.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="step1.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="step1.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback col-12" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.first_name == '' || step1.first_name == null"
                id="checkbox-1"
                name="checkbox-1"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_given_name"
              >
                Does not have a given name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_given_name"
              >
                {{ errors.have_a_given_name[0] }}
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.middle_name == '' || step1.middle_name == null"
                id="checkbox-2"
                name="checkbox-2"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_middle_name"
              >
                Does not have a middle name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_middle_name"
              >
                {{ errors.have_a_middle_name[0] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="step1.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.date_of_birth"
                >
                  {{ errors.date_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <p class="text-info">Licence details</p>
            </div>
          </div>
          <div class="row">
            <div class="alert alert-danger mb-5 col-12" v-if="arrErr.length">
              <p
                class="fs-800 mb-0"
                v-for="(item, index) in arrErr"
                :key="index"
              >
                {{ item }}
              </p>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step2.licence_number"
                />
                <label class="floatingLabel" for="firstName"
                  >Licence Number</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <select class="form-control" v-model="step2.state_code">
                  <option
                    :value="issuing_agency.value"
                    v-for="(issuing_agency, i) in optionsIssuingAgency"
                    :key="i"
                  >
                    {{ issuing_agency.text }}
                  </option>
                </select>
                <label
                  class="floatingLabel vueBootSelect"
                  for="state_of_Licence"
                  >State of Licence</label
                >
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="sits-file-upload_2">
                <div class="upload-view">
                  <div class="mr500">
                    <input
                      type="file"
                      id="actual-btn"
                      ref="file"
                      @change="uploadFile"
                      hidden
                    />
                    <!-- our custom upload button -->
                    <label class="upload-label" for="actual-btn"
                      ><i class="fas fa-cloud-upload-alt text-info"></i> Upload
                      Copy of Licence</label
                    >
                  </div>

                  <wdImageAvatarPreview
                    :title="'Copy of Licence'"
                    :coverImgUrl="step2.doc_urls"
                    :fontAwClass="'fas fa-file-alt'"
                    :fontAwSize="'fs-2300'"
                  ></wdImageAvatarPreview>
                </div>
                <!-- name of file chosen -->
                <span v-if="document.name" id="file-chosen">{{
                  document.name
                }}</span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-right">
              <router-link
                v-if="loginUserObj.role_id == 9"
                class="btn btn-secondary btn-sm mr-2"
                :to="{ name: 'dashboardCC' }"
                >Cancel</router-link
              >
              <button
                v-if="can_cc_update()"
                class="btn btn-info btn-sm"
                @click="fnUpdate()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Submit
              </button>

              <button
                v-if="can_cvs_update()"
                class="btn btn-info btn-sm"
                @click="fnSave()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep2Ins></appStep2Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as chkDriversLicenceOffencesService from "../../services/chkDriversOffences.service";

import * as messageService from "../../services/message.service";
import appStep2Ins from "../policeCheck/step2Ins.vue";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";

export default {
  name: "CheckDriversLicenceTrafficOffenceCom",
  props: ["mode", "projectId", "productId", "userId"],
  components: {
    appStep2Ins,
    wdImageAvatarPreview,
  },
  data() {
    return {
      loginUserObj: {},
      errors: {},
      arrErr: [],
      stateOfLicence: "",
      document: "",
      loading: false,
      step1: {
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      step2: {
        licence_number: "",
        state_code: "",
        doc_urls: "",
        status: 1,
      },
      optionsIssuingAgency: [
        { value: "ACT", text: "Australian Capital Territory" },
        { value: "NSW", text: "New South Wales" },
        { value: "NT", text: "Northern Territory" },
        { value: "QLD", text: "Queensland" },
        { value: "SA", text: "South Australia" },
        { value: "TAS", text: "Tasmania" },
        { value: "VIC", text: "Victoria" },
        { value: "WA", text: "Western Australia" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getDriversOffences", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchDriversOffences", "fetchUserFromId"]),

    uploadFile() {
      this.document = this.$refs.file.files[0];
    },

    fnValidation() {
      this.arrErr = [];
      if (
        this.step2.licence_number == "" ||
        this.step2.licence_number == null
      ) {
        this.arrErr.push("Licence_number is required");
      }

      if (this.step2.state_code == "" || this.step2.state_code == null) {
        this.arrErr.push("State of Licence is required");
      }

      if (this.step2.doc_urls == "") {
        if (this.document.name == "" || this.document.name == null) {
          this.arrErr.push("Please upload copy of Licence");
        }
      }

      return this.arrErr;
    },

    fnUpdate: async function () {
      this.loading = true;
      this.errors = {};
      this.fnValidation();

      if (this.arrErr.length > 0) {
        return;
      }

      const formData = new FormData();
      formData.append("file", this.document);
      formData.append("project_id", this.projectId);
      formData.append("licence_number", this.step2.licence_number);
      formData.append("state_code", this.step2.state_code);
      formData.append("status", 2);

      try {
        const res = await chkDriversLicenceOffencesService.updateUserInfo(
          this.userId,
          this.step1
        );

        console.log("3");
        const response = await chkDriversLicenceOffencesService.addOrUpdate(
          this.projectId,
          formData
        );

        if (response.data.status_code == 200) {
          this.fetchDriversOffences(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              "Something wrong"
            );
            break;
        }

        console.log("Something wrong - fnCreate", error);
        this.loading = false;
      }
    },

    /**
     * Save the data if CVS
     */
    fnSave: async function () {
      this.loading = true;
      this.errors = {};
      this.fnValidation();

      if (this.arrErr.length > 0) {
        return;
      }

      const formData = new FormData();
      formData.append("file", this.document);
      formData.append("project_id", this.projectId);
      formData.append("licence_number", this.step2.licence_number);
      formData.append("state_code", this.step2.state_code);
      formData.append("status", this.step2.status);

      try {
        const res = await chkDriversLicenceOffencesService.updateUserInfo(
          this.userId,
          this.step1
        );

        const response = await chkDriversLicenceOffencesService.addOrUpdate(
          this.projectId,
          formData
        );

        if (response.data.status_code == 200) {
          this.document = "";

          this.fetchDriversOffences(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          this.loading = false;
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              "Something wrong"
            );
            break;
        }

        console.log("Something wrong - fnCreate", error);

        this.loading = false;
      }
    },

    /**
     * Check CC can update (submit)
     */
    can_cc_update() {
      let bol = false;
      let obj = this.getDriversOffences;

      if (Object.keys(obj).length === 0) {
        bol = true;
      }

      if (obj.status < 2) {
        bol = true;
      }

      return bol;
    },

    /**
     * Check CVS can update (submit)
     */
    can_cvs_update() {
      let bol = false;
      let obj = this.getDriversOffences;

      if (
        Object.keys(obj).length > 0 &&
        obj.status > 1 &&
        this.loginUserObj.role_id != 9
      ) {
        bol = true;
      }

      return bol;
    },
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchDriversOffences(this.projectId);
  },

  watch: {
    getUserFromId(newVal) {
      Object.assign(this.step1, newVal);
    },
    getDriversOffences(newVal) {
      Object.assign(this.step2, newVal);
    },
  },
};
</script>