<template>
  <div class="p-3">
    <appCheckDriversLicenceTrafficOffence
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckDriversLicenceTrafficOffence>
  </div>
</template>
<script>
import * as encodeDecodeService from "../../services/encodeDecode.service";
import appCheckDriversLicenceTrafficOffence from "../../components/checkDriversLicenceTrafficOffence/checkDriversLicenceTrafficOffence.vue";

export default {
  name: "CheckDriversLicenceTrafficOffenceCom",

  components: {
    appCheckDriversLicenceTrafficOffence,
  },

  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
};
</script>